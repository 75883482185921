import TableData from "../component/TableData";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import { TextField } from "@mui/material";

const PlayersProfile = () => {
;
  const headerData = [
    "",
    "FullName",
    "Email",
    "PhoneNo",
    "Role",
    "IsSuspended",
    "IsVerified",
  ];

 

  return (
    <>
      {" "}

      <TableData headerData={headerData} />
    </>
  );
};

export default PlayersProfile;
