import React from "react";
import { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { AuthContext } from "../auth/ContextAuthentication";

const headerData = [
  "Player Name",
  "Age",
  "Contact Number",
  "Monthly Fee",
  "Registration Fee",
];
function AcademyRegistrations() {
  const { academyRegistrations } = useContext(AuthContext);

  return (
    <div className="p-4 w-full">
      <h2 className="text-xl font-bold mb-4">Academy Registrations</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell
                  key={index}
                  align="left"
                  style={{ fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {academyRegistrations?.map((academy, index) => (
              <TableRow key={academy.academyId}>
                <TableCell style={{ cursor: "pointer" }} hover>
                  {academy?.userId?.fullName}
                </TableCell>
                {/* <TableCell>{academy?.name}</TableCell> */}
                <TableCell>{academy?.playerAge}</TableCell>
                <TableCell>{academy?.contactNumber}</TableCell>
                <TableCell>{academy?.monthlyFee}</TableCell>
                <TableCell>{academy?.registrationFeePaid ? "Paid" : "Not Paid"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AcademyRegistrations;
