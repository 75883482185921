import React, { useContext, useEffect } from "react";
import MatchTable from "../component/MatchTable";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";

const MatchProfile = () => {
  const axiosInstance = useAxiosConfig();
  const { setMatchData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const headerData = [
    "MatchType",
    "Organizer",
    "Status",
    "GroundName",
    "Date",
    "StartTime",
    "EndTime",
  ];
  useEffect(() => {
    const fetchApi = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("api/admin/allmatches");
        setMatchData(response.data.matches);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchApi();
  }, [setMatchData]);
  return (
    <>
      <h2 className="text-xl font-bold mb-4">Matches</h2>

      <MatchTable headerData={headerData} />
    </>
  );
};

export default MatchProfile;
