import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";
import BookingTable from "../component/BookingTable";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import * as XLSX from "xlsx";

const SportsProfile = () => {
  const axiosInstance = useAxiosConfig();
  const { bookingData, setBookingData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [sports, setSports] = useState([]);
  const [grounds, setGrounds] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedGround, setSelectedGround] = useState(null);
  const [groundMap, setGroundMap] = useState([]);
  const [isLoadingGrounds, setIsLoadingGrounds] = useState(false);
  const [isFetchingBookings, setIsFetchingBookings] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { adminRole } = useContext(AuthContext);

  console.log("Start Date is ", startDate);
  console.log("End Date is ", endDate);

  const headerData = [
    "User's Name",
    "Phone No",
    "Booking date",
    "Ground Name",
    "Field",
    "Time Slot",
    "Advance Amount",
    "Remaining Amount",
    "Promo Code",
    "Manager's Name",
    "Manager's No",
    "Status",
    "Actions",
  ];

  useEffect(() => {
    const fetchAllSports = async () => {
      setIsLoadingGrounds(true);
      try {
        const response = await axiosInstance.get("/api/ground/get-all-sports");
        const sports = response.data.data;
        setSports(sports);
      } catch (error) {
        setError("Error fetching sports");
      } finally {
        setIsLoadingGrounds(false);
      }
    };

    fetchAllSports();
  }, []);
  console.log("The bookings for the Grounds are 1::::::: ", bookingData);

  const fetchGroundsBySport = async (sportId) => {
    setIsLoadingGrounds(true);
    try {
      const response = await axiosInstance.get(
        `api/admin/sports/get-grounds-by-sports/${sportId}`
      );
      const grounds = response.data.data;
      setGrounds(grounds);
    } catch (error) {
      setError("Error fetching grounds");
      setGrounds([]);
    } finally {
      setIsLoadingGrounds(false);
    }
  };

  // useEffect(() => {
  //   console.log("rendered again");
  //   const fetchBookings = async () => {
  //     if (!selectedSport && !selectedGround) {
  //       return;
  //     }
  //     console.log("rendered after having the values");

  //     try {
  //       let url;
  //       if (selectedSport && selectedGround) {
  //         console.log("The selection started")
  //         url = `api/admin/sports/get-bookings-by-grounds-and-sports/${selectedGround._id}/${selectedSport._id}`;
  //         setIsFetchingBookings(true);
  //       }

  //       if (startDate && endDate) {
  //         console.log("The selection started of date");
  //         url += `?startDate=${startDate}&endDate=${startDate}`;
  //       }
  //       const response = await axiosInstance.get(url);
  //       setBookingData(response.data.data || []);
  //     } catch (error) {
  //       setError("Error fetching bookings");
  //       setBookingData([]);
  //     } finally {
  //       setIsFetchingBookings(false);
  //     }
  //   };

  //   fetchBookings();
  // }, [selectedSport, selectedGround, startDate, endDate]);


  useEffect(() => {
    console.log("rendered again");
    const fetchBookings = async () => {
      // Only proceed if both sport and ground are selected
      if (!selectedSport || !selectedGround) {
        return;
      }

      try {
        let url = `api/admin/sports/get-bookings-by-grounds-and-sports/${selectedGround._id}/${selectedSport._id}`;
        setIsFetchingBookings(true);

        // Only add date parameters if both dates are present
        if (startDate && endDate) {
          url += `?startDate=${startDate}&endDate=${endDate}`;
        }

        const response = await axiosInstance.get(url);
        setBookingData(response.data.data || []);
      } catch (error) {
        setError("Error fetching bookings");
        setBookingData([]);
      } finally {
        setIsFetchingBookings(false);
      }
    };

    // Create separate conditions for API calls
    const shouldFetchInitial = selectedSport && selectedGround;
    const shouldFetchWithDates = shouldFetchInitial && startDate && endDate;

    if (
      shouldFetchWithDates ||
      (shouldFetchInitial && !startDate && !endDate)
    ) {
      fetchBookings();
    }
  }, [selectedSport, selectedGround, startDate, endDate]);
  
  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleExportToExcel = () => {
    const formattedData = bookingData.map((row) => ({
      UserName: row.owner.fullName || "N/A",
      PhoneNO: row.owner.phone_no || "N/A",
      BookingDate: formatDate(row.date) || "N/A",
      GroundName: row.ground.name || "N/A",
      Field: row.fieldData.name || "N/A",
      TimeSlot: `${formatTime(row.startTime)} - ${formatTime(row.endTime)}`,
      AdvanceAmount: row.paidAmount || "N/A",
      RemainingAmount: row.remainingAmount || "N/A",
      PromoCode: row.promoCode || "N/A",
      ManagersName: row.owner.fullName || "N/A",
      ManagersNo: row.owner.phone_no || "N/A",
      status: row.status || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    worksheet["!cols"] = [{ wch: 30 }, { wch: 40 }, { wch: 30 }];

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "UserName",
          "PhoneNO",
          "BookingDate",
          "GroundName",
          "Field",
          "TimeSlot",
          "AdvanceAmount",
          "RemainingAmount",
          "PromoCode",
          "ManagersName",
          "ManagersNo",
          "status",
        ],
      ],
      {
        origin: "A1",
      }
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Players");

    XLSX.writeFile(workbook, "PlayersData.xlsx");
  };

  return (
    <div className="p-4 w-full">
      <h2 className="text-xl font-bold mb-4">Bookings</h2>

      <div className="mb-4 flex gap-4">
        <Autocomplete
          options={sports}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Sport"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedSport(newValue);
            if (newValue) {
              fetchGroundsBySport(newValue._id);
            } else {
              setGrounds([]);
            }
          }}
          loading={isLoadingGrounds}
        />
        <Autocomplete
          options={grounds}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Ground"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedGround(newValue);
          }}
          loading={isLoadingGrounds}
        />

        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />

        <Button
          variant="contained"
          onClick={handleExportToExcel}
          disabled={adminRole !== "Super Admin"}
          sx={{
            backgroundColor:
              adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
            boxShadow: "none",
            "&:hover": {
              backgroundColor:
                adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
              boxShadow: "none",
            },
            cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
          }}
        >
          Export
        </Button>
      </div>
      <BookingTable
        headerData={headerData}
        isLoading={isFetchingBookings}
        groundMap={groundMap}
      />
    </div>
  );
};

export default SportsProfile;
